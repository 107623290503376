import React, { useEffect } from "react";

// Функция инициализации виджета согласно документации
function initWidget(config) {
  let timeoutId;
  // Проверяем, что window существует (если используется SSR, модифицируйте этот код)
  const context = window;
  if (!context) return;

  // Функция, которая каждые 500 мс пытается инициализировать виджет
  const tryInitWidget = () => {
    const HotelWidget = context.HotelWidget;
    const TIMEOUT_DELAY = 500;
    if (HotelWidget) {
      if (config?.version === "2") {
        HotelWidget.init(config);
        // Здесь можно вызывать методы: add, isExists, remove, update
      } else {
        HotelWidget.init(config, "https://bookonline24.ru/");
      }
    } else {
      timeoutId = setTimeout(tryInitWidget, TIMEOUT_DELAY);
    }
  };

  tryInitWidget();

  // Возвращаем функцию для остановки попыток инициализации (вызывайте её в cleanup useEffect)
  return () => clearTimeout(timeoutId);
}

// Функция динамической загрузки скрипта виджета
function loadWidgetScript(callback) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://bookonline24.ru/widget.js";
  script.async = true;
  script.onload = script.onreadystatechange = function() {
    const readyState = this.readyState;
    if (!readyState || readyState === "complete") {
      callback();
    }
  };
  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode.insertBefore(script, firstScript);
}

const HotelWidgetComponent = () => {
  useEffect(() => {
    // Загружаем скрипт виджета
    loadWidgetScript(() => {
      // Инициализируем виджет с нужными настройками
      const cleanupInit = initWidget({
        hotelId: "6adec522-90fc-4217-87a5-17c6a6a68464", // ID организации
        version: "2",
        baseUrl: "https://bookonline24.ru/",
        hooks: {
          onError: (e) => console.error("onError", e),
          onInit: () => console.log("onInit"),
          onBooking: (v) => console.log("onBooking", v)
        }
      });

      // Добавляем различные блоки виджета
      if (window.HotelWidget) {
        window.HotelWidget.add({
          type: "bookingForm",
          inline: true,
          appearance: {
            container: "WidgetHorizontalBlockId" // ID контейнера для виджета
          }
        });
        window.HotelWidget.add({
          type: "roomsList",
          appearance: {
            container: "WidgetRoomsListId"
          }
        });
        window.HotelWidget.add({
          type: "hourlyObjectsList",
          appearance: {
            container: "WidgetHourlyObjectsListId"
          }
        });
        // window.HotelWidget.add({
        //     type: "availabilityCalendar",
        //     months: 2,
        //     appearance: {
        //         container: "WidgetHorizontalAvailabilityCalendarId"
        //     }
        // });
        window.HotelWidget.add({
          type: "showCheckAvailabilityButtonForMobileDevices",
          appearance: {
            container: "WidgetShowCheckAvailabilityButtonForMobileDevicesId"
          }
        });
      }

      // Очистка при размонтировании компонента
      return () => {
        cleanupInit();
      };
    });
  }, []);

  return (
    <div className="section" id="pricing">
      <div className="section-header">
        <h1>Бронирование</h1>
      </div>
      <div className="pricing-category">
        {/* Контейнеры для встраивания виджетов */}
        <div id="WidgetHorizontalBlockId"></div>
        <div id="WidgetRoomsListId"></div>
        <div id="WidgetHourlyObjectsListId"></div>
        {/* <div id="WidgetHorizontalAvailabilityCalendarId"></div> */}
        <div id="WidgetShowCheckAvailabilityButtonForMobileDevicesId"></div>
      </div>
    </div>
  );
};

export default HotelWidgetComponent;
