import Header from './components/Header/Header.js';
import Welcome from './components/Welcome/Welcome.js';
import Area from './components/Area/Area.js';
import Houses from './components/Houses/Houses.js';
import Bathhouse from './components/Bathhouse/Bathouse.js';
import Activities from './components/Activities/Activities.js'
import Pricing from './components/Pricing/Pricing.js';
import Map from './components/Map/Map.js';

import './App.css';

function App() {
  return (
    <>
        <title>Горы Рядом</title>
      <Header />
      <Welcome />
      <Area />
      <Houses />
      <Bathhouse />
      <Activities />
      <Pricing />
      <Map />
    </>
  );
}

export default App;
