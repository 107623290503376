import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Gallery.css';
import PrevIcon from './assets/chevron-left.svg';
import NextIcon from './assets/chevron-right.svg';

import Gallery0 from './assets/Gallery0.webp';
import Gallery1 from './assets/Gallery1.webp';
import Gallery2 from './assets/Gallery2.webp';
import Gallery3 from './assets/Gallery3.webp';

function Gallery() {
  //const images = [
  //  Gallery0,
  //  Gallery0,
  //  Gallery0,
  //]

  const CustomPrevArrow = ({ onClick }) => (
    <div className="arrow arrow-prev" onClick={onClick}>
      <img src={PrevIcon} alt="Previous" />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div className="arrow arrow-next" onClick={onClick}>
      <img src={NextIcon} alt="Next" />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  return (
    <div>
      <Slider {...settings}>
        <img className="gallery-img" src={Gallery3} alt="" />
        <img className="gallery-img" src={Gallery2} alt="" />
        <img className="gallery-img" src={Gallery1} alt="" />
        <img className="gallery-img" src={Gallery0} alt="" />
      </Slider>
    </div>
  );
};

export default Gallery;
