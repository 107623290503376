import './Houses.css';
import HousesMainPreview from './assets/HousesMainPreview.webp';
import HousesPreview00 from './assets/HousesPreview00.webp';
import HousesPreview01 from './assets/HousesPreview01.webp';
import HousesPreview02 from './assets/HousesPreview02.webp';
import HousesPreview03 from './assets/HousesPreview03.webp';
import HousesPreview04 from './assets/HousesPreview04.webp';
import HousesPreview05 from './assets/HousesPreview05.webp';

import HousesMainPreview06 from './assets/HousesMainPreview06.webp';
import HousesPreview07 from './assets/HousesPreview07.webp';
import HousesPreview08 from './assets/HousesPreview08.webp';
import HousesPreview09 from './assets/HousesPreview09.webp';
import HousesPreview10 from './assets/HousesPreview10.webp';
import HousesPreview11 from './assets/HousesPreview11.webp';
import HousesPreview12 from './assets/HousesPreview12.webp';

import Tree from './assets/Tree.svg';
import Bedroom from './assets/Bedroom.svg';
import Livingroom from './assets/Livingroom.svg';
import Childroom from './assets/Childroom.svg';
import Bathroom from './assets/Bathroom.svg';

function Barnhouse() {
  return (
    <>
      <div className="houses-preview">
        <h2>Барнхаус</h2>
        <img src={HousesMainPreview} alt="" />
        <div>
          <div className="houses-preview-descr">
            <div>
              <img src={Tree} alt="" />
            </div>
            <p>Наш аутентичный барнхаус, построенный из натурального дерева, предлагает уникальный опыт проживания в новом стиле. Просторные комнаты с деревенским шармом и современными удобствами создают идеальный баланс между простотой и комфортом</p>
          </div>
          <div className="houses-preview-multiimg">
            <img src={HousesPreview00} alt="" />
            <img src={HousesPreview01} alt="" />
            <img src={HousesPreview02} alt="" />
            <img src={HousesPreview03} alt="" />
            <img src={HousesPreview04} alt="" />
            <img src={HousesPreview05} alt="" />
          </div>
        </div>
      </div>
      <div className="houses-options" style={{marginBottom: "150px"}}>
        <div>
          <div className="houses-options-img-container">
            <img src={Bedroom} alt=""/>
          </div>
          <p className="houses-options-title">Спальня</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Двуспальная кровать, прикроватные тумбочки, конвектор отопления, теплый пол</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Childroom} alt="" />
          </div>
          <p className="houses-options-title">Детская</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Две односпальных кровати, тумбочки, конвектор отопления, теплый пол</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Livingroom} alt="" />
          </div>
          <p className="houses-options-title">Гостиная</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Теплый пол, обеденный стол, 4 стула, диван, кухонная зона с раковиной, микроволновая печь, чайник, телевизор</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Bathroom} alt="" />
          </div>
          <p className="houses-options-title">Санузел</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Теплый пол, конвектор, раковина с подстольем, унитаз, душевая кабина</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Bathroom} alt="" />
          </div>
          <p className="houses-options-title">Веранда</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Кресла "Кентукки", подсветка</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Bathroom} alt="" />
          </div>
          <p className="houses-options-title">Доп. Услуги</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Аренда индукционной плиты, посуды для приготовления (кастрюли, сковородки)</p>
        </div>
      </div>
    </>
  )
}

function Tinyhouse() {
  return (
    <>
      <div className="houses-preview">
        <h2>Тинихаус</h2>
        <img src={HousesMainPreview06} alt="" />
        <div>
          <div className="houses-preview-descr">
            <div>
              <img src={Tree} alt="" />
            </div>
            <p>Компактный и уютный тинихаус - идеальное место для тех, кто ищет минималистичный комфорт на фоне природы. Продуманный дизайн и функциональность этого маленького дома обеспечат вам все необходимое для приятного отдыха</p>
          </div>
          <div className="houses-preview-multiimg">
            <img src={HousesPreview07} alt="" />
            <img src={HousesPreview08} alt="" />
            <img src={HousesPreview09} alt="" />
            <img src={HousesPreview10} alt="" />
            <img src={HousesPreview11} alt="" />
            <img src={HousesPreview12} alt="" />
          </div>
        </div>
      </div>
      <div className="houses-options">
        <div>
          <div className="houses-options-img-container">
            <img src={Bedroom} alt="" />
          </div>
          <p className="houses-options-title">Спальня</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Двуспальная кровать с доп. местом для ребёнка, теплый потолок</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Bathroom} alt="" />
          </div>
          <p className="houses-options-title">Санузел</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Теплый потолок, раковина с подстольем, унитаз, душевая кабина</p>
        </div>
        <div>
          <div className="houses-options-img-container">
            <img src={Livingroom} alt="" />
          </div>
          <p className="houses-options-title">Терраса</p>
          <div className="houses-options-separator"></div>
          <p className="houses-options-descr">Кресла "Андирандак"</p>
        </div>
      </div>
    </>
  )
}


function Area() {
  return (
    <div className="section" id="houses">
      <div className="section-header">
        <h1>Домики</h1>
        <p>Наши уютные домики глэмпинга в лесу — это идеальное место для отдыха среди природы с ноткой роскоши. Отпразднуйте приключения в комфорте, окруженные звуками леса и звездами над головой. Вернитесь к природе, не пожертвовав комфортом</p>
      </div>
      <Barnhouse />
      <Tinyhouse />
    </div>
  )
}

export default Area;
