function ActivityDetails({ image, description }) {
  return (
    <div>
      <div>
        <img src={image} className="activity-selected-preview" alt="Фотография занятия"/>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default ActivityDetails;
