import { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Header.css'
import Logo from './assets/Logo.svg';
import BMenu from './assets/bmenu.svg';

function HeaderMenu({enableBMenu}) {
  const changeState = () => {
    enableBMenu(false);
  }

  return (
    <div className="header-menu">
      <div>
          <AnchorLink className="header-menu-button" href="#area" onClick={changeState}>Территория</AnchorLink>
          <AnchorLink className="header-menu-button" href="#houses" onClick={changeState}>Домики</AnchorLink>
          <AnchorLink className="header-menu-button" href="#activities" onClick={changeState}>Активности</AnchorLink>
          <AnchorLink className="header-menu-button" href="#pricing" onClick={changeState}>Стоимость</AnchorLink>
          <AnchorLink className="header-menu-button" href="#map" onClick={changeState}>Карта</AnchorLink>
      </div>
    </div>
  )
}

function Header() {
  const [isBMenuEnabled, enableBMenu] = useState(false);

  const changeBMenuState = () => {
    enableBMenu(!isBMenuEnabled);
  }

  return (
    <div className="header-wrapper">
      <div>
        <img src={Logo} alt='Логотип' />
        <div className="header-options">
          <AnchorLink className="header-buttons" href="#area">Территория</AnchorLink>
          <AnchorLink className="header-buttons" href="#houses">Домики</AnchorLink>
          <AnchorLink className="header-buttons" href="#activities">Активности</AnchorLink>
          <AnchorLink className="header-buttons" href="#pricing">Бронирование</AnchorLink>
          <AnchorLink className="header-buttons" href="#map">Карта</AnchorLink>
        </div>
        <button id="header-menu-toggle" onClick={changeBMenuState}>
          <img src={BMenu} alt=""/>
        </button>
        {isBMenuEnabled ? <HeaderMenu enableBMenu={enableBMenu} /> : <></>}
      </div>
    </div>
  )
}

export default Header;
